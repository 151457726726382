import React, { useState, useCallback, useEffect } from 'react';
import { Button, Typography, IconButton, CircularProgress, TextField, Tooltip } from '@mui/material';
import { Delete, Add, Refresh, Edit } from '@mui/icons-material';
import { Venue } from '../../../../shared/models';
import { uploadVenuePictures, createVenueSectionPictures, deleteVenuePicture, deleteVenueSectionPicture } from '../../lib/firebase/venues';
import { normalizeImageUrl } from '../../lib/util';
import { doc, getDoc } from 'firebase/firestore';
import { runTransaction } from 'firebase/firestore';
import { db } from '../../lib/firebase/app';
import OptimizedImage from '../../components/OptimizedImage';

interface PictureEditorProps {
    venue: Venue;
    onVenueUpdate: (venue: Venue) => Promise<void>;
    shouldShowImages?: boolean;
    shouldShowSection?: boolean;
    missingFields?: string[];
}

const PictureEditor: React.FC<PictureEditorProps> = ({ venue, onVenueUpdate, shouldShowImages = true, shouldShowSection = true, missingFields }) => {
    const [pictures, setPictures] = useState<Record<string, string[]>>({
        venue: venue.PhotoUrls || [],
        ...Object.fromEntries(Object.entries(venue.Sections || {}).map(([id, section]) => [id, section.Images || []]))
    });
    const [photoAltTexts, setPhotoAltTexts] = useState<Record<string, string>>(venue.PhotoAltTexts || {});
    const [editingAltText, setEditingAltText] = useState<string | null>(null);
    const [altTextValue, setAltTextValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [deleteError, setDeleteError] = useState<string | null>(null);
    const [countdown, setCountdown] = useState<number>(15);
    const [recentUpload, setRecentUpload] = useState<boolean>(false);

    // Refresh timer notification component
    const RefreshNotification = () => (
        <div className="bg-[#1a1a2e]/90 border-2 border-[#E0B1F1] rounded-xl p-4 flex items-center gap-4 shadow-lg animate-pulse">
            <CircularProgress size={24} sx={{ color: '#E0B1F1' }} />
            <div className="flex-1">
                <Typography variant="subtitle1" sx={{ color: '#E0B1F1', fontWeight: 600 }}>
                    Processing Images...
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(224, 177, 241, 0.8)' }}>
                    Your images are being processed. Auto-refreshing in <span className="font-bold text-white">{countdown}</span> seconds.
                </Typography>
            </div>
            <Button
                variant="contained"
                size="small"
                onClick={handleRefresh}
                sx={{
                    backgroundColor: '#E0B1F1',
                    color: '#1a1a2e',
                    '&:hover': {
                        backgroundColor: '#d396ed',
                    }
                }}
            >
                Refresh Now
            </Button>
        </div>
    );

    const handleRefresh = useCallback(async () => {
        setIsLoading(true);
        try {
            const venueRef = doc(db, 'venues', venue.ID);
            const venueDoc = await getDoc(venueRef);
            if (venueDoc.exists()) {
                const refreshedVenue = venueDoc.data() as Venue;
                setPictures({
                    venue: refreshedVenue.PhotoUrls || [],
                    ...Object.fromEntries(Object.entries(refreshedVenue.Sections || {}).map(([id, section]) => [id, section.Images || []]))
                });
                setPhotoAltTexts(refreshedVenue.PhotoAltTexts || {});
                await onVenueUpdate(refreshedVenue);
                setRecentUpload(false);
                setUploadProgress(0); // Reset upload progress after refresh
            }
        } catch (error) {
            console.error('Failed to refresh venue:', error);
            alert('Failed to refresh venue data');
        } finally {
            setIsLoading(false);
        }
    }, [venue.ID, onVenueUpdate]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (recentUpload) {
            setCountdown(15);
            timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        handleRefresh();
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [recentUpload, handleRefresh]);

    // Get all variants for a normalized URL
    const getAllVariants = (normalizedUrl: string, urls: string[]): string[] => {
        return urls.filter(url => normalizeImageUrl(url) === normalizedUrl);
    };

    // Get unique normalized URLs in current order
    const getUniqueNormalizedUrls = (urls: string[] | undefined | null): string[] => {
        if (!urls || !Array.isArray(urls)) return [];

        const seen = new Set<string>();
        return urls.map(url => normalizeImageUrl(url))
            .filter(normalizedUrl => {
                if (seen.has(normalizedUrl)) return false;
                seen.add(normalizedUrl);
                return true;
            });
    };
    const movePicture = useCallback(async (listKey: string, currentIndex: number, newIndex: number) => {
        setIsLoading(true);
        try {
            // Get latest venue data in transaction
            const venueRef = doc(db, 'venues', venue.ID);
            return await runTransaction(db, async (transaction) => {
                const venueDoc = await transaction.get(venueRef);
                if (!venueDoc.exists()) {
                    throw new Error('Venue not found');
                }

                const currentVenue = venueDoc.data() as Venue;
                const currentUrls = listKey === 'venue'
                    ? currentVenue.PhotoUrls || []
                    : currentVenue.Sections?.[listKey]?.Images || [];

                const normalizedUrls = getUniqueNormalizedUrls(currentUrls);

                if (newIndex < 0 || newIndex >= normalizedUrls.length || newIndex === currentIndex) {
                    return;
                }

                // Move the normalized URL
                const [movedNormalizedUrl] = normalizedUrls.splice(currentIndex, 1);
                normalizedUrls.splice(newIndex, 0, movedNormalizedUrl);

                // Reconstruct full URL array with all variants in new order
                const reorderedUrls = normalizedUrls.flatMap(normalizedUrl =>
                    getAllVariants(normalizedUrl, currentUrls)
                );

                // Update Firestore in transaction
                if (listKey === 'venue') {
                    transaction.update(venueRef, { PhotoUrls: reorderedUrls });
                } else {
                    transaction.update(venueRef, { [`Sections.${listKey}.Images`]: reorderedUrls });
                }

                // Update local state
                setPictures(prev => ({ ...prev, [listKey]: reorderedUrls }));
                const updatedVenue = { ...currentVenue };
                if (listKey === 'venue') {
                    updatedVenue.PhotoUrls = reorderedUrls;
                } else {
                    updatedVenue.Sections[listKey].Images = reorderedUrls;
                }
                await onVenueUpdate(updatedVenue);
            });

        } catch (error) {
            console.error('Failed to move picture:', error);
            alert('An error occurred while moving the picture.');
        } finally {
            setIsLoading(false);
        }
    }, [pictures, venue.ID, onVenueUpdate]);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, listKey: string) => {
        setIsLoading(true);
        setUploadProgress(0);
        try {
            const files = Array.from(event.target.files || []);
            if (listKey === 'venue') {
                await uploadVenuePictures(venue.ID, files, (progress) => setUploadProgress(progress));
            } else {
                await createVenueSectionPictures(venue.ID, listKey, files, (progress) => setUploadProgress(progress));
            }

            // Refresh immediately after upload completes
            await handleRefresh();

        } catch (error) {
            console.error('Failed to upload pictures:', error);
            alert('An error occurred while uploading pictures.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeletePicture = async (listKey: string, index: number) => {
        setIsLoading(true);
        setDeleteError(null);
        try {
            const normalizedUrls = getUniqueNormalizedUrls(pictures[listKey]);
            const normalizedUrlToDelete = normalizedUrls[index];
            const variantsToDelete = getAllVariants(normalizedUrlToDelete, pictures[listKey]);

            await runTransaction(db, async (transaction) => {
                // Get latest venue data in transaction
                const venueRef = doc(db, 'venues', venue.ID);
                const venueDoc = await transaction.get(venueRef);
                if (!venueDoc.exists()) {
                    throw new Error('Venue not found');
                }

                const latestVenue = venueDoc.data() as Venue;

                // Delete all variants, but continue even if some fail
                for (const variant of variantsToDelete) {
                    try {
                        if (listKey === 'venue') {
                            await deleteVenuePicture(venue.ID, variant);
                        } else {
                            await deleteVenueSectionPicture(venue.ID, listKey, variant);
                        }
                    } catch (error) {
                        // Log the error but continue with deletion process
                        console.warn(`Failed to delete image from storage: ${variant}`, error);
                    }
                }

                // Update venue data
                const updatedVenue = { ...latestVenue };
                if (listKey === 'venue') {
                    updatedVenue.PhotoUrls = (latestVenue.PhotoUrls || []).filter(url =>
                        !variantsToDelete.includes(url)
                    );
                } else {
                    updatedVenue.Sections[listKey].Images = (latestVenue.Sections[listKey].Images || []).filter(url =>
                        !variantsToDelete.includes(url)
                    );
                }

                // Write updated venue in transaction
                transaction.update(venueRef, updatedVenue);

                // Update local state after successful transaction
                setPictures(prev => ({
                    ...prev,
                    [listKey]: listKey === 'venue' ? updatedVenue.PhotoUrls : updatedVenue.Sections[listKey].Images
                }));
                await onVenueUpdate(updatedVenue);
            });

        } catch (error) {
            console.error('Failed to delete picture:', error);
            setDeleteError('Failed to delete picture. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const updateAltText = async (imageUrl: string, altText: string) => {
        setIsLoading(true);
        try {
            const venueRef = doc(db, 'venues', venue.ID);
            await runTransaction(db, async (transaction) => {
                const venueDoc = await transaction.get(venueRef);
                if (!venueDoc.exists()) {
                    throw new Error('Venue not found');
                }

                const currentVenue = venueDoc.data() as Venue;
                const updatedAltTexts = { ...(currentVenue.PhotoAltTexts || {}), [imageUrl]: altText };

                transaction.update(venueRef, { PhotoAltTexts: updatedAltTexts });

                setPhotoAltTexts(updatedAltTexts);
                const updatedVenue = { ...currentVenue, PhotoAltTexts: updatedAltTexts };
                await onVenueUpdate(updatedVenue);
            });
        } catch (error) {
            console.error('Failed to update alt text:', error);
            alert('An error occurred while updating the alt text.');
        } finally {
            setIsLoading(false);
            setEditingAltText(null);
        }
    };

    const startEditingAltText = (imageUrl: string) => {
        setEditingAltText(imageUrl);
        setAltTextValue(photoAltTexts[imageUrl] || '');
    };

    const renderPictureList = (listKey: string) => {
        const normalizedUrls = getUniqueNormalizedUrls(pictures[listKey]);
        const displayUrls = normalizedUrls.map(normalizedUrl => {
            const variants = getAllVariants(normalizedUrl, pictures[listKey]);
            return variants.find(url => url.includes('_1600x1600')) || variants[0];
        });

        return (
            <div className="space-y-4">
                {recentUpload && <RefreshNotification />}

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {displayUrls.map((url, index) => (
                        <div
                            key={url}
                            className="group relative rounded-xl overflow-hidden shadow-md border border-[#1a1a2e]/30 transition-shadow hover:shadow-lg hover:border-[#E0B1F1]/40 flex flex-col bg-[#1a1a2e]/20"
                        >
                            <div className="aspect-square relative w-full">
                                <OptimizedImage
                                    src={url}
                                    alt={photoAltTexts[url] || `Picture ${index + 1}`}
                                    fill
                                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    style={{ objectFit: 'cover', objectPosition: 'center' }}
                                    quality={75}
                                    className="transition-opacity duration-300"
                                />
                                <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-between p-3">
                                    <button
                                        onClick={() => {
                                            if (!isLoading) {
                                                const newIndex = parseInt(prompt(`Enter new position (1-${displayUrls.length}):`) || '', 10) - 1;
                                                if (!isNaN(newIndex)) {
                                                    movePicture(listKey, index, newIndex);
                                                }
                                            }
                                        }}
                                        className="text-white bg-black/60 hover:bg-black/80 rounded-full h-9 w-9 flex items-center justify-center font-medium"
                                        disabled={isLoading}
                                    >
                                        {index + 1}
                                    </button>
                                    <div className="flex gap-2">
                                        <IconButton
                                            onClick={() => startEditingAltText(url)}
                                            disabled={isLoading}
                                            size="small"
                                            sx={{
                                                color: 'white',
                                                bgcolor: 'rgba(224, 177, 241, 0.3)',
                                                '&:hover': { bgcolor: 'rgba(224, 177, 241, 0.5)' }
                                            }}
                                        >
                                            <Edit fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDeletePicture(listKey, index)}
                                            disabled={isLoading}
                                            size="small"
                                            sx={{
                                                color: 'white',
                                                bgcolor: 'rgba(255, 0, 0, 0.3)',
                                                '&:hover': { bgcolor: 'rgba(255, 0, 0, 0.5)' }
                                            }}
                                        >
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>

                            {editingAltText === url ? (
                                <div className="p-3 bg-[#1a1a2e]/80 border-t border-[#E0B1F1]/20">
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={altTextValue}
                                        onChange={(e) => setAltTextValue(e.target.value)}
                                        placeholder="Enter alt text for accessibility"
                                        InputProps={{
                                            sx: {
                                                color: 'white',
                                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                                borderRadius: '6px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#E0B1F1',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#d396ed',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#E0B1F1',
                                                }
                                            }
                                        }}
                                    />
                                    <div className="flex justify-end mt-2 gap-2">
                                        <Button
                                            size="small"
                                            onClick={() => setEditingAltText(null)}
                                            sx={{
                                                color: '#E0B1F1',
                                                '&:hover': { bgcolor: 'rgba(224, 177, 241, 0.1)' }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() => updateAltText(url, altTextValue)}
                                            sx={{
                                                bgcolor: '#E0B1F1',
                                                color: '#1a1a2e',
                                                '&:hover': { bgcolor: '#d396ed' }
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="p-3 border-t border-[#1a1a2e]/40 bg-gradient-to-b from-[#1a1a2e]/50 to-[#1a1a2e]/70"
                                    onClick={() => startEditingAltText(url)}
                                >
                                    {photoAltTexts[url] ? (
                                        <div className="flex items-center">
                                            <div className="flex-1">
                                                <Typography noWrap variant="body2" sx={{ color: '#E0B1F1', fontWeight: 500 }}>
                                                    Alt text:
                                                </Typography>
                                                <Typography noWrap variant="body2" sx={{ color: 'white', opacity: 0.9 }}>
                                                    {photoAltTexts[url]}
                                                </Typography>
                                            </div>
                                            <Edit fontSize="small" sx={{ color: 'rgba(224, 177, 241, 0.5)', ml: 1 }} />
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-center bg-[#1a1a2e]/30 p-2 rounded-md border border-dashed border-[#E0B1F1]/40 hover:border-[#E0B1F1]/70 transition-colors">
                                            <Typography variant="body2" sx={{ color: 'rgba(224, 177, 241, 0.8)', fontStyle: 'italic', mr: 1 }}>
                                                Add alt text
                                            </Typography>
                                            <Edit fontSize="small" sx={{ color: 'rgba(224, 177, 241, 0.5)' }} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}

                    <label className="relative aspect-square rounded-xl border-2 border-dashed border-[#E0B1F1]/60 hover:border-[#E0B1F1] cursor-pointer flex items-center justify-center transition-colors bg-[#1a1a2e]/20 hover:bg-[#1a1a2e]/40 shadow-md">
                        <input
                            type="file"
                            className="hidden"
                            multiple
                            onChange={(e) => handleFileUpload(e, listKey)}
                            accept="image/*"
                            disabled={isLoading}
                        />
                        <div className="text-center p-6">
                            <div className="rounded-full bg-[#E0B1F1]/10 p-3 mx-auto mb-2 w-16 h-16 flex items-center justify-center">
                                <Add sx={{ fontSize: 32, color: '#E0B1F1' }} />
                            </div>
                            <Typography sx={{ color: '#E0B1F1', fontWeight: 500 }}>
                                Add Photos
                            </Typography>
                        </div>
                    </label>
                </div>
            </div>
        );
    };

    return (
        <div className="space-y-8 text-white">
            <div className="flex items-center justify-between">
                <Typography variant="h5" sx={{ color: '#E0B1F1', fontWeight: 600 }}>
                    Venue Pictures
                </Typography>
                {shouldShowImages && (
                    <Button
                        onClick={handleRefresh}
                        disabled={isLoading}
                        startIcon={<Refresh />}
                        sx={{
                            color: '#E0B1F1',
                            borderColor: '#E0B1F1',
                            '&:hover': {
                                borderColor: '#d396ed',
                                backgroundColor: 'rgba(224, 177, 241, 0.1)'
                            }
                        }}
                        variant="outlined"
                    >
                        Refresh
                    </Button>
                )}
            </div>

            {/* Main venue pictures section */}
            {shouldShowImages ? (
                renderPictureList('venue')
            ) : (
                <div className="space-y-4">
                    <Typography variant="body1" sx={{ color: 'rgba(224, 177, 241, 0.8)' }}>
                        Upload images here. You will be able to view and reorder them at a later step.
                    </Typography>

                    <label className="relative aspect-square w-full max-w-xs rounded-xl border-2 border-dashed border-[#E0B1F1] hover:border-[#d396ed] cursor-pointer flex items-center justify-center transition-colors">
                        <input
                            type="file"
                            className="hidden"
                            multiple
                            onChange={(e) => handleFileUpload(e, 'venue')}
                            accept="image/*"
                            disabled={isLoading}
                        />
                        <div className="text-center p-6">
                            <Add sx={{ fontSize: 32, color: '#E0B1F1' }} />
                            <Typography sx={{ color: '#E0B1F1', mt: 1 }}>
                                Add Venue Photos
                            </Typography>
                        </div>
                    </label>

                    {recentUpload && <RefreshNotification />}
                </div>
            )}

            {/* Section pictures */}
            {shouldShowSection && (
                <div className="space-y-6 mt-8">
                    <Typography variant="h5" sx={{ color: '#E0B1F1', fontWeight: 600 }}>
                        Section Pictures
                    </Typography>

                    {Object.entries(venue.Sections || {})
                        .sort(([, a], [, b]) => (b.Capacity || 0) - (a.Capacity || 0))
                        .map(([sectionId, section]) => (
                            <div
                                key={sectionId}
                                className="bg-[#1a1a2e]/50 rounded-xl p-6 space-y-4"
                            >
                                <Typography variant="h6" sx={{ color: '#E0B1F1' }}>
                                    {section.Name}
                                </Typography>
                                {shouldShowImages ? renderPictureList(sectionId) : (
                                    <div className="space-y-4">
                                        <Typography sx={{ color: 'rgba(224, 177, 241, 0.8)' }}>
                                            Upload images here. You will be able to view and reorder them later.
                                        </Typography>

                                        <label className="relative aspect-square w-full max-w-xs rounded-xl border-2 border-dashed border-[#E0B1F1] hover:border-[#d396ed] cursor-pointer flex items-center justify-center transition-colors">
                                            <input
                                                type="file"
                                                className="hidden"
                                                multiple
                                                onChange={(e) => handleFileUpload(e, sectionId)}
                                                accept="image/*"
                                                disabled={isLoading}
                                            />
                                            <div className="text-center p-6">
                                                <Add sx={{ fontSize: 32, color: '#E0B1F1' }} />
                                                <Typography sx={{ color: '#E0B1F1', mt: 1 }}>
                                                    Add Section Photos
                                                </Typography>
                                            </div>
                                        </label>

                                        {recentUpload && <RefreshNotification />}
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            )}

            {uploadProgress > 0 && (
                <div className="fixed bottom-4 right-4 bg-[#1a1a2e] p-4 rounded-xl border border-[#E0B1F1]">
                    <Typography sx={{ color: '#E0B1F1' }}>
                        Uploading: {uploadProgress}%
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default PictureEditor;